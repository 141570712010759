import en from "@/assets/locales/en.json";
import zhhant from "@/assets/locales/zh-Hant.json";
import zh from "@/assets/locales/zh.json";

export const locales = {
  en,
  zh,
  "zh-HANT": zhhant,
};

export type Locales = keyof typeof locales;
